export const FolderSvg: React.FC = () => {
	return (
		<svg
			width='43'
			height='43'
			viewBox='0 0 43 43'
			className='folder-svg'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M40.3982 18.3233C40.2133 18.0849 39.9761 17.8921 39.705 17.7598C39.4339 17.6275 39.136 17.5592 38.8343 17.5601H33.3616V13.2271C33.361 12.7206 33.1595 12.2349 32.8013 11.8768C32.4431 11.5186 31.9575 11.3171 31.451 11.3165H20.1831L18.7139 7.78912C18.5694 7.44059 18.3246 7.14281 18.0106 6.93353C17.6967 6.72426 17.3277 6.61289 16.9503 6.61356H3.23333C2.72679 6.61415 2.24117 6.81563 1.883 7.17381C1.52483 7.53198 1.32334 8.0176 1.32275 8.52413V38.6526H36.1024L40.7591 20.0254C40.8332 19.733 40.8392 19.4275 40.7766 19.1324C40.714 18.8373 40.5846 18.5605 40.3982 18.3233ZM3.96817 36.0072V9.25898H16.4604L18.4197 13.962H30.7164V17.5601H11.3743C10.9316 17.5596 10.5015 17.7073 10.1526 17.9796C9.80368 18.252 9.55603 18.6334 9.44916 19.063L5.21342 36.0072H3.96817ZM34.0369 36.0072H7.94027L11.8906 20.2056H37.9873L34.0369 36.0072Z'
				fill='#EEEEEE'
			/>
		</svg>
	)
}
