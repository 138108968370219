export const LinkSvg: React.FC = () => {
	return (
		<svg
			width='26'
			height='26'
			viewBox='0 0 26 26'
			fill='none'
			className='link-svg'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M22.2215 1.58725H3.1745C2.75354 1.58725 2.34982 1.75448 2.05215 2.05215C1.75448 2.34982 1.58725 2.75354 1.58725 3.1745V22.2215C1.58725 22.6425 1.75448 23.0462 2.05215 23.3439C2.34982 23.6416 2.75354 23.8088 3.1745 23.8088H22.2215C22.6425 23.8088 23.0462 23.6416 23.3439 23.3439C23.6416 23.0462 23.8088 22.6425 23.8088 22.2215V3.1745C23.8088 2.75354 23.6416 2.34982 23.3439 2.05215C23.0462 1.75448 22.6425 1.58725 22.2215 1.58725ZM3.1745 0C2.33257 0 1.52513 0.334456 0.929791 0.929791C0.334456 1.52513 0 2.33257 0 3.1745L0 22.2215C0 23.0635 0.334456 23.8709 0.929791 24.4662C1.52513 25.0616 2.33257 25.396 3.1745 25.396H22.2215C23.0635 25.396 23.8709 25.0616 24.4662 24.4662C25.0616 23.8709 25.396 23.0635 25.396 22.2215V3.1745C25.396 2.33257 25.0616 1.52513 24.4662 0.929791C23.8709 0.334456 23.0635 0 22.2215 0L3.1745 0Z'
				fill='#EEEEEE'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16.6662 7.93626H10.3172C10.1067 7.93626 9.90484 8.01988 9.75601 8.16871C9.60717 8.31755 9.52356 8.51941 9.52356 8.72989C9.52356 8.94037 9.60717 9.14223 9.75601 9.29107C9.90484 9.4399 10.1067 9.52352 10.3172 9.52352H14.7504L8.16804 16.1043C8.01902 16.2533 7.9353 16.4554 7.9353 16.6662C7.9353 16.8769 8.01902 17.079 8.16804 17.228C8.31707 17.3771 8.51918 17.4608 8.72993 17.4608C8.94068 17.4608 9.1428 17.3771 9.29182 17.228L15.8726 10.6457V15.0789C15.8726 15.2894 15.9562 15.4912 16.105 15.6401C16.2538 15.7889 16.4557 15.8725 16.6662 15.8725C16.8767 15.8725 17.0785 15.7889 17.2274 15.6401C17.3762 15.4912 17.4598 15.2894 17.4598 15.0789V8.72989C17.4598 8.51941 17.3762 8.31755 17.2274 8.16871C17.0785 8.01988 16.8767 7.93626 16.6662 7.93626Z'
				fill='#EEEEEE'
			/>
		</svg>
	)
}
