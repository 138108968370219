export const Linkedin: React.FC = () => {
	return (
		<svg
			width='25'
			height='25'
			viewBox='0 0 57 59'
			fill='none'
			className='linkedin-svg'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M11.2482 0.206001C5.14556 0.206001 0.150879 5.30552 0.150879 11.5366V46.6973C0.150879 52.9282 5.14545 58.026 11.2482 58.026H45.6853C51.7881 58.026 56.7809 52.9283 56.7809 46.6973V11.5366C56.7809 5.30564 51.7881 0.206001 45.6853 0.206001H11.2482ZM14.0394 9.74746C16.9655 9.74746 18.7679 11.7088 18.8235 14.2869C18.8235 16.8081 16.9654 18.8245 13.9828 18.8245H13.9279C11.0575 18.8245 9.20221 16.8082 9.20221 14.2869C9.20221 11.7088 11.1136 9.74746 14.0393 9.74746H14.0394ZM39.2553 21.7972C44.8828 21.7972 49.1013 25.5526 49.1013 33.6229V48.6886H40.5491V34.633C40.5491 31.1009 39.3114 28.6912 36.2164 28.6912C33.8537 28.6912 32.4453 30.3154 31.827 31.8844C31.6011 32.4457 31.5456 33.2297 31.5456 34.0149V48.6886H22.9934C22.9934 48.6886 23.1056 24.8787 22.9934 22.4134H31.5474V26.1342C32.6839 24.3439 34.7167 21.7972 39.2553 21.7972V21.7972ZM9.70667 22.4153H18.2589V48.6887H9.70667V22.4153V22.4153Z'
				fill='#EEEEEE'
			/>
		</svg>
	)
}
