export const KaggleSvg: React.FC = () => {
	return (
		<svg
			width='32'
			height='25'
			viewBox='0 0 32 32'
			fill='none'
			className='kaggle-svg'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M25.099 31.812C25.074 31.932 24.943 32 24.724 32H20.541C20.292 32 20.073 31.891 19.885 31.672L12.978 22.885L11.046 24.713V31.53C11.046 31.843 10.895 31.999 10.583 31.999H7.33799C7.02599 31.999 6.86899 31.843 6.86899 31.53V0.469C6.86899 0.161 7.02599 0 7.33799 0H10.583C10.895 0 11.046 0.161 11.046 0.469V19.593L19.317 11.234C19.541 11.01 19.76 10.906 19.978 10.906H24.297C24.489 10.906 24.614 10.983 24.677 11.145C24.74 11.344 24.724 11.484 24.63 11.562L15.89 20.021L25.005 31.364C25.13 31.505 25.161 31.64 25.098 31.844L25.099 31.812Z'
				fill='#EEEEEE'
			/>
		</svg>
	)
}
