export const GithubSvg: React.FC = () => {
	return (
		<svg
			width='32'
			height='33'
			viewBox='0 0 32 33'
			fill='none'
			className='github-svg'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M16.3186 3.35768C9.12891 3.35768 3.30176 9.18483 3.30176 16.3745C3.30176 22.1246 7.03036 27.0043 12.2037 28.727C12.8549 28.8445 13.0898 28.4438 13.0898 28.0993C13.0898 27.79 13.0794 26.9704 13.0755 25.8859C9.45388 26.6715 8.6891 24.1397 8.6891 24.1397C8.09921 22.6362 7.24439 22.2356 7.24439 22.2356C6.0633 21.4277 7.33444 21.446 7.33444 21.446C8.64212 21.5374 9.32729 22.7876 9.32729 22.7876C10.4888 24.7766 12.3759 24.2023 13.1146 23.8695C13.2334 23.0278 13.5727 22.4535 13.9433 22.1286C11.0539 21.801 8.01569 20.6838 8.01569 15.6945C8.01569 14.2759 8.52336 13.1118 9.35208 12.2035C9.22027 11.8733 8.77002 10.5486 9.48129 8.75808C9.48129 8.75808 10.5736 8.40701 13.0598 10.0906C14.1216 9.8017 15.2169 9.65426 16.3173 9.65205C17.4177 9.65384 18.513 9.80129 19.5747 10.0906C22.0622 8.4057 23.1532 8.75808 23.1532 8.75808C23.8645 10.5486 23.4182 11.8733 23.2824 12.2035C24.1177 13.1118 24.6188 14.2746 24.6188 15.6945C24.6188 20.6969 21.578 21.7958 18.6781 22.1181C19.1414 22.5201 19.5591 23.3136 19.5591 24.5273C19.5591 26.2683 19.5434 27.6725 19.5434 28.0993C19.5434 28.4477 19.7757 28.8523 20.44 28.7244C25.6107 26.9991 29.3354 22.1233 29.3354 16.3745C29.3354 9.18483 23.5082 3.35768 16.3186 3.35768Z'
				fill='#EEEEEE'
			/>
		</svg>
	)
}
